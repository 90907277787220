<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Encabezado -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly class="py-1">
            <v-btn
              v-if="permiso == 1"
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              @click="nuevoEditar(null)"
              title="Agregar Banner"
            >
              <v-icon style="font-size: 20px;">fas fa-plus</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                <span style="font-size: 24px;">Filtros</span>
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row >
                  <v-col cols="12" sm="4" md="3" class="py-1">
                    Empresas
                    <v-autocomplete
                      v-model="empresa_codigo"
                      item-text="empresa_abrev"
                      item-value="empresa_codigo"
                      :items="empresas"
                      hide-details
                      clearable
                      outlined
                      dense
                      @change="controlarEmpresa()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="4" md="4" class="py-1 d-flex align-center">
                    <v-switch
                      v-model="inhabilitado"
                      class="mt-5 pa-0"
                      label="Inhabilitado"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" sm="4" md="5" class="d-flex justify-end py-1">
                    <BtnFiltro
                      :loading="load"
                      clase="pa-0"
                      @clear="limpiarFiltros()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="empresa_codigo == 0 ? headers : headers2"
          :items="banners"
          :loading="load"
          :search="search"
          dense
          item-key="id_row"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom v-if="item.img && item.inhabilitado == 0">
              <template v-slot:activator="{ on }">
                <v-btn small icon :color="permiso == 0 ? 'primary' : 'orange'" @click="nuevoEditar(item)" v-on="on">
                  <v-icon class="" small>fas fa-{{permiso == 1 ? 'pen': 'eye' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ permiso == 1 ? 'Editar' : 'Ver' }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="permiso == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon :color="item.inhabilitado == 0 ? 'error' : 'success'" @click="inhabilitarHabilitar(item)" v-on="on">
                  <v-icon class="" small>{{ item.inhabilitado == 0 ? 'fas fa-ban' : 'fas fa-check' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.inhabilitado == 0 ? 'Inhabilitar' : 'Habilitar' }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.img">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="warning" v-on="on">
                  <v-icon class="" small>fas fa-exclamation-triangle</v-icon>
                </v-btn>
              </template>
              <span>No posee imagen</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Componentes -->
    <ModalNvoEditarBanner
      v-model="dialog"
      :empresas="empresas"
      :datos="objModal"
      :nuevo="objModal.nuevo"
      :permiso="permiso"
      @actualizar="buscar"
    />
  </div>
</template>

<script>
import SearchDataTable from '../../components/util/SearchDataTable.vue';
import ModalNvoEditarBanner from '../../components/generales/ModalNvoEditarBanner.vue';
import BtnFiltro from '../../components/util/BtnFiltro.vue';
import { tipos_archivos, getBase64, order_list_by} from '../../util/utils';

export default{
  name: 'Banners',
  data(){
    return{
      load:    false,
      panel:   0,
      search:  '',
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Nombre Archivo', value: 'nombre_archivo' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Acción', value: 'acciones' },
      ],
      headers2: [
        { text: 'Id', value: 'id' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Nombre Archivo', value: 'nombre_archivo' },
        { text: 'Acción', value: 'acciones' },
      ],
      banners:        [],
      empresas:       [],
      permiso:        0,
      empresa_codigo: 0,
      inhabilitado:   0,
      dialog:         false,
      objModal: {
        descripcion:    '',
        empresa:        0,
        id:             0,
        id_row:         null,
        img:            '',
        inhabilitado:   0,
        nombre_archivo: 0,
        nuevo:          false,
      }
    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res  = await this.$store.dispatch('genericos/getPermiso', {funcion_codigo: 171});
      const res2 = await this.$store.dispatch('genericos/getEmpresas');
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.permiso = res.permiso;

      if(res2.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res2.message, color: 'error' })
      }
      this.empresas = res2.empresas
      order_list_by(this.empresas, 'empresa_abrev');
    },
    async buscar(){
      this.search = '';
      if(this.empresa_codigo == null || this.empresa_codigo == undefined){
        this.empresa_codigo = 0;
      }
      this.banners = [];
      this.$store.state.loading = true;
      const res                 = await this.$store.dispatch('genericos/getBannersForm',{ empresa_codigo: this.empresa_codigo, inhabilitado: this.inhabilitado });
      this.$store.state.loading = false;
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.banners = res.banners
    },
    limpiarFiltros(){
      this.empresa_codigo = 0;
      this.inhabilitado   = 0;
    },
    async nuevoEditar(item){
      if(item){
        this.objModal = {
          descripcion:    item.descripcion,
          empresa:        item.empresa,
          id:             item.id,
          id_row:         item.id_row,
          img:            item.img,
          inhabilitado:   item.inhabilitado,
          nombre_archivo: item.nombre_archivo,
          nuevo:          false,
        }
      }else{
        this.objModal = {
          descripcion:    '',
          empresa:        0,
          id:             0,
          id_row:         null,
          img:            '',
          inhabilitado:   0,
          nombre_archivo: 0,
          nuevo:          true,
        }
      }
      this.dialog = true;
    },
    async inhabilitarHabilitar(item){
      let cad = '';
      let accion;
      if(item.inhabilitado == 0){
        cad    = 'inhabilitar';
        accion = 1
      }else{
        cad    = 'habilitar'
        accion = 0
      }

      let modal = await this.$swal.fire({
        icon: 'question',
        title: 'Confirmar acción',
        html: `¿Está seguro de ${cad} el banner: <strong>${item.descripcion}</strong> ?`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (!modal.isConfirmed) return

      this.$store.state.loading = true;
      const res                 = await this.$store.dispatch('genericos/inhabilitarHabilitarBanner',{ id: item.id, accion: accion });
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }

      this.$store.dispatch('show_snackbar', { text: `El banner se ${accion == 1 ? 'inhabilitó' : 'habilitó'} con exito`, color: 'success' })

      let pos = this.banners.map(e => e.id).indexOf(item.id);
      if(pos != -1){
        if(accion == 1){
          this.banners[pos].inhabilitado = 1;
        }else this.banners[pos].inhabilitado = 0;
      }

    },
  },
  components:{
    ModalNvoEditarBanner, SearchDataTable, BtnFiltro
  },
  computed:{
  },
  watch:{
    
  }
}
</script>