<template>
<div>
<v-dialog
    v-model="activo"
    width="850"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">{{ nuevo? 'Nuevo feriado' : 'Detalle del feriado' }}</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-form @submit.prevent="guardarCambios()">
          <v-row class="pt-2">
            <v-col cols="5" sm="3" md="2" class="pt-1">
              Año
              <v-text-field
                v-model="feriadoCopia.anio"
                type="number"
                tabindex="1"
                hide-details
                outlined
                dense
                clearable
                @change="controlarAnio()"
              ></v-text-field>
            </v-col>
            <v-col cols="7" sm="3" md="3" class="pt-1">
              Fecha
              <FechaPickerVue v-model="feriadoCopia.fecha"
                hideDetails
                outline clearable/>
            </v-col>
            <v-col cols="12" sm="6" md="7" class="pt-1">
              Descripción
              <v-text-field
                v-model="feriadoCopia.nombre_feriado"
                tabindex="1"
                hide-details
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pt-1">
              Tipo Feriado
              <v-autocomplete
                v-model="feriadoCopia.id_tipo"
                item-text="tipo_nombre"
                item-value="tipo_id"
                tabindex="1"
                :items="tipos"
                hide-details
                outlined
                dense
                clearable
                @change="controlarFeriado()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pt-1">
              Sucursal
              <v-autocomplete
                v-model="feriadoCopia.sucursal_codigo"
                item-text="sucursal_nombre"
                item-value="sucursal_codigo"
                tabindex="1"
                :items="sucursales"
                hide-details
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="2" md="2" class="pt-1">
              Ventas
              <v-checkbox
                v-model="feriadoCopia.ventas"
                class="ma-0 pb-1"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="2" md="2" class="pt-1">
              Proyectada
              <v-checkbox
                v-model="feriadoCopia.liq_tar_proyectada"
                class="ma-0 pb-1"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex pb-4" :class="pantallaChica ? 'justify-center' : 'justify-end'">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="activo = false"
        >cancelar</v-btn>
        <BtnConfirmarVue
          :texto="`Aplicar cambios al Feriado. ¿Confirma esta accion?`"
          :nombre="'GUARDAR CAMBIOS'"
          :loading="load"
          @action="guardarEmit()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import moment from 'moment';
import BtnConfirmarVue from '../util/BtnConfirmar.vue';
import FechaPickerVue from '../util/FechaPicker.vue';

export default {
  name: 'ModalNvoEditarFeriados',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    feriado:{
      get(){
        return this.datos.feriado;
      },
    },
    tipos:{
      get(){
        return this.datos.tipos;
      },
    },
    sucursales:{
      get(){
        return this.datos.sucursales;
      },
    },
    nuevo:{
      get(){
        return this.datos.nuevo;
      },
    },
  },
  data(){
    return{
      feriadoCopia: {},
      load: false,
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  created(){
  },
  methods:{
    async init(){

    },
    controlarAnio(){
      if(this.feriadoCopia.anio && parseInt(this.feriadoCopia.anio) < 2000){
        this.feriadoCopia.anio = null;
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar un Año superior al 2000', color: 'info' })
      }
    },
    guardarEmit(){
      if(!this.feriadoCopia.anio){
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar un Año.', color: 'warning' })
      }
      if(!this.feriadoCopia.fecha){
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar una fecha.', color: 'warning' })
      }
      if(!this.feriadoCopia.id_tipo){
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar un Tipo de Feriado.', color: 'warning' })
      }
      /*if(!this.feriadoCopia.sucursal_codigo){
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar una Sucursal.', color: 'warning' })
      }*/
      if(!this.feriadoCopia.nombre_feriado){
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar una Descripción.', color: 'warning' })
      }
      this.feriadoCopia.anio = parseInt(this.feriadoCopia.anio)
      this.feriadoCopia.ventas = this.feriadoCopia.ventas? 1 : 0
      this.feriadoCopia.liq_tar_proyectada = this.feriadoCopia.liq_tar_proyectada? 1 : 0
      this.$emit('guardarCambios', this.feriadoCopia, this.nuevo);
    },
    controlarFeriado(){
      if(this.feriadoCopia.id_tipo == 1){
        this.feriadoCopia.sucursal_codigo = null;
      }
    },
  },
  components:{
    FechaPickerVue, BtnConfirmarVue
  },
  watch:{
    activo: function(val){
      if(val){
        this.feriadoCopia = JSON.parse(JSON.stringify(this.feriado));
        if(!this.feriadoCopia.fecha){
          this.feriadoCopia.fecha = moment(new Date()).format('DD/MM/YYYY')
        }else{
          this.feriadoCopia.fecha = moment(this.feriadoCopia.fecha).format('DD/MM/YYYY')
        }
      }
    },
    'feriadoCopia.id_tipo': {
      handler: function(val){
        if(val){
          this.feriadoCopia.nombre_tipo_feriado = (this.tipos.filter(e => e.tipo_id == val))[0].tipo_nombre
        }else this.feriadoCopia.nombre_tipo_feriado = ''
      },
      deep: true,
    },
    'feriadoCopia.sucursal_codigo': {
      handler: function(val){
        if(val){
          this.feriadoCopia.sucursal_nombre = (this.sucursales.filter(e => e.sucursal_codigo == val))[0].sucursal_nombre
        }else this.feriadoCopia.sucursal_nombre = ''
      },
      deep: true,
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>

<style>

</style>